.serviceNew-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    // background-color: beige;

    .service-header {

        h1 {
            padding-top: 2vh;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-size: 35px;
            font-weight: bold;
        }
    }

    .card-section {
        margin: 50px;
        display: grid;
        background-color: transparent;
        grid-template-columns: auto auto auto;
        grid-auto-flow: row;
        grid-gap: 50px;
        // background-color: yellow;

        .card-fram {
            width: 350px;
            height: 465px;
            // height: 700px;
            // background-color: greenyellow;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            // background-color: #0b1c27;
            transition: 0.3s ease all;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;

            &:hover .introduction p{
                cursor: pointer;
            }

            img {
                border-radius: 10px 10px 0 0 ;
                height: auto;
            }

            .introduction {
                height: 70px;
                width: 100%;
                padding: 6px;
                box-sizing: border-box;
                position: absolute;
                bottom: 0;
                background: rgb(27, 27, 27, .8);
                color: white;
                transition: .5s;

                h1 {
                    margin: 5px;
                    font-size: 28px;
                }

                p {
                    text-align: justify;
                    visibility: hidden;
                    opacity: 0;
                }
            }

            &:hover .introduction {
                height: 465px;
                bottom: 0px;
                background: rgba(27, 27, 27, 0.767);
                // overflow: auto;
            }

            &:hover p {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}



@media (max-width: 1155px) {
    .serviceNew-container {
    
        .card-section {
            grid-gap: 10px;
        }
    }
}

@media (max-width: 1175px) {
    .serviceNew-container {
    
        .card-section {
    
            .card-fram {

                .introduction {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    .serviceNew-container {
    
        .card-section {
    
            .card-fram {
                width: 300px;
                height: 465px;
    
                .introduction {
                    width: 100%;
                }

                &:hover .introduction {
                    height: 500px;
                }
            }
        }
    }
}

@media (max-width: 980px) {
    .serviceNew-container {
    
        .card-section {
            grid-template-columns: auto auto;
            grid-gap: 50px;
    
            .card-fram {
                width: 335px;
                height: 500px;
    
                .introduction {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 820px) {
    .serviceNew-container {
    
        .card-section {
            grid-gap: 50px;
    
            .card-fram {
                width: 335px;
                height: 500px;
            }
        }
    }
}

@media (max-width: 740px) {
    .serviceNew-container {
    
        .card-section {
    
            .card-fram {
                width: 250px;
                height: 400px;

                &:hover .introduction {
                    height: 400px;
                    overflow: auto;
                }
            }
        }
    }
}

@media (max-width: 560px) {
    .serviceNew-container {
   
        .card-section {
            grid-gap: 10px;
    
            .card-fram {
                width: 250px;
                height: 400px;
            }
        }
    }
}

@media (max-width: 520px) {
    .serviceNew-container {
    
        .card-section {
            grid-template-columns: auto;
    
            .card-fram {
                width: 450px;
                height: 500px;

                &:hover .introduction {
                    height: 500px;
                }
            }
        }
    }
}

@media (max-width: 460px) {
    .serviceNew-container {
    
        .card-section {
    
            .card-fram {
                width: 400px;
                height: 500px;
            }
        }
    }
}

@media (max-width: 410px) {
    .serviceNew-container {
    
        .card-section {
    
            .card-fram {
                width: 350px;
                height: 500px;
            }
        }
    }
}

@media (max-width: 360px) {
    .serviceNew-container {
    
        .card-section {
    
            .card-fram {
                width: 300px;
                height: 450px;

                &:hover .introduction {
                    height: 450px;
                }
            }
        }
    }
}

@media (max-width: 310px) {
    .serviceNew-container {
    
        .card-section {
    
            .card-fram {
                width: 250px;
                height: 450px;
            }
        }
    }
}

