.ouriimportance-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100wh;
    height: 100vh;
    // background-color: red;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    .card-body {
        display: flex;
        flex-direction: column;
        width: 1250px;
        height: 400px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        border-radius: 10px;

        .importance-header {
            display: flex;
            align-items: center;
            padding-left: 30px;
            font-size: 28px;
            font-weight: bold;
            width: 100%;
            height: 60px;
            // background-color: aliceblue;
        }

        .importance-body {
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            grid-auto-flow: row;
            grid-gap: 10px;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 340px;
            // background-color: aquamarine;

            .card-fram {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 230px;
                height: 330px;
                // background-color: white;
                // border: solid black 2px;
                text-align: justify;

                .image {       
                    padding-top: 10px;          
                    display: flex;  
                    align-items: center;
                    justify-content: center;
                    height: 100px;
                    width: 100%;
                    // background-color: red;

                    img {
                        width: 124px;
                    }
                }

                .title {
                    height: 50px;
                    width: 85%;
                    align-items: flex-start;
                    font-size: 20px;
                    font-weight: 400;
                    padding-top: 10px;
                    padding-top: 20px;
                    // padding: 10px;
                    // background-color: greenyellow;
                }

                .detail {
                    width: 85%;
                    height: 180px;
                    padding-top: 20px;
                    // background-color: yellow;
                }
            }
        }
    }
}

@media (max-width:1239px) {
    .ouriimportance-container {
    
        .card-body {
    
            .importance-body {
    
                .card-fram {
                    width: 230px;
                }
            }
        }
    }
}

@media (max-width:1195px) {
    .ouriimportance-container {
        margin-top: 50px;
        margin-bottom: 50px;
        height: auto;
    
        .card-body {
            height: auto;
    
            .importance-body {
                grid-template-columns: auto auto auto auto;
                height: auto;
            }
        }
    }
}

@media (max-width:1115px) {
    .ouriimportance-container {
    
        .card-body {
            width: 1020px;
    
            .importance-body {
    
                .card-fram {
                    width: 230px;
                }
            }
        }
    }
}

@media (max-width:1050px) {
    .ouriimportance-container {
    
        .card-body {
            width: 980px;
    
            .importance-body {
    
                .card-fram {
                    width: 230px;
                }
            }
        }
    }
}

@media (max-width:1050px) {
    .ouriimportance-container {
    
        .card-body {
            width: 900px;
    
            .importance-body {
    
                .card-fram {
                    width: 200px;
                }
            }
        }
    }
}

@media (max-width:955px) {
    .ouriimportance-container {
    
        .card-body {
            width: 800px;
    
            .importance-body {
                grid-template-columns: auto auto auto;
            }
        }
    }
}

@media (max-width:825px) {
    .ouriimportance-container {
    
        .card-body {
            width: 700px;
    
            .importance-body {
                
            }
        }
    }
}

@media (max-width:720px) {
    .ouriimportance-container {
    
        .card-body {
            width: 500px;
    
            .importance-body {
                grid-template-columns: auto auto;
            }
        }
    }
}

@media (max-width:550px) {
    .ouriimportance-container {
    
        .card-body {
            width: 450px;
        }
    }
}

@media (max-width:480px) {
    .ouriimportance-container {
    
        .card-body {
            width: 80%;
    
            .importance-body {
                grid-template-columns: auto;
            }
        }
    }
}

@media (max-width:370px) {
    .ouriimportance-container {
    
        .card-body {
            width: 80%;
    
            .importance-body {
                grid-template-columns: auto;
            }

            .importance-header {
                display: flex;
                align-items: center;
                padding-left: 60px;
                font-size: 20px;
                font-weight: bold;
                width: 100%;
                height: 60px;
                // background-color: aliceblue;
            }
        }
    }
}