.homeNew-container {
    width: 100%;
    height: 101vh;
    // height: auto;
    // background-color: beige;
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-image: url("../../images//background.jpg");
    background-position: center;
    background-size:cover ;
    overflow: hidden;

    .home-top-section{
        width: 100%;
        height: 55vh;
        // height: auto;
        // background-color: aqua;
        // background-image: url("../../images//background.jpg");
        background-position: center;
        background-size:cover ;
        // filter: brightness(70%);

        .front-image-and-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.432);

            .name {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 1400px;
                height: 66%;
                height: 325px;
                // background-image: url('../../images//frant-name.png');
                background-position: center;
                background-size: cover;
                // background-color: red;
                // overflow: auto;

                img {
                    width: 650px;
                }

                h1 {
                    color: white;
                    font-size: 30px;
                    letter-spacing: 10px;
                }
            }

            .detail {
                display: flex;
                width: 100%;
                height: 40%;
                // background-color: wheat;

                .detail-left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    height: 100%;
                    // background-color: red;

                    .client {
                        font-weight: bold;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        height: 70px;
                        // background-color: yellow;

                        h1 {
                            font-size: 35px;
                            color: white;
                        }

                        hr {
                            color: #006FB4;
                            size: 2px;
                            width: 80px;
                        }

                        p {
                            color: white;
                        }
                    }

                    .workers {
                        display: flex;

                        font-weight: bold;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        height: 70px;
                        // background-color: greenyellow;

                        h1 {
                            font-size: 35px;
                            color: white;

                        }

                        hr {
                            color: #006FB4;
                            size: 2px;
                            width: 80px;
                        }

                        p {
                            color: white;
                        }
                    }

                    .branches {
                        display: flex;
                        font-weight: bold;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        height: 70px;
                        // background-color: yellow;

                        h1 {
                            font-size: 35px;
                            color: white;

                        }

                        hr {
                            color: #006FB4;
                            size: 2px;
                            width: 80px;
                        }

                        p {
                            color: white;
                        }
                    }
                }

                .detail-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 50%;
                    height: 100%;
                    // background-color: yellow;
                    margin-right: 20px;

                    button {
                        font-weight: bold;
                        width: 120px;
                        height: 100px;
                        background-color: black;
                        color: white;
                        border-radius: 10px;
                        border: solid white 2px;
                        margin-bottom: 10px;

                        &:hover {
                            border: solid orange 2px;
                            transform: scale(1.01);
                        }
                    }
                }
            }
        }
    }

    .home-footer-section {
        align-items: center;
        justify-content: center;
        width:100%;
        height: 45vh;
        // height: auto;
        display: grid;
        background-color: transparent;
        grid-template-columns: auto auto auto;
        grid-auto-flow: row;
        grid-gap: 30px;
        // background-color: #2b2e30;
        // background-image: url(../../images//footer.jpg);
        background-size: cover;
        // background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(0, 0, 0, 0.432);

        .card-fram {
            width: 400px;
            height: 180px;
            background-color: greenyellow;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: space-between;
            background-color: #0b1c27a9;
            transition: 0.3s ease all;
            border-radius: 10px;
            overflow: hidden;
            border: solid white 4px;
            margin: 10px;

            .card-header {
                width: 94%;
                height: 30px;
                // background-color: red;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .nameOfTheCard {

                    p {
                        text-transform: uppercase;
                        font-weight: bold;
                        color: white;
                    }
                }
            }

            .card-body {
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    padding-top: 10px;
                    color: white;
                    text-align: justify;
                    width: 95%;
                }
            }
        }
    }
}

@media (max-width:1366px) {
    .homeNew-container {
        height: auto;

        .home-top-section{
            // height: 50vh;
            // background-color: red;
            height: auto;
        }
    
        .home-footer-section {
            height: 40vh;
            // height: auto;
        }
    }
}

@media only screen and (max-width: 1239px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {

                .name {
                    width: 800px;
                    // background: red;
                    
                    img {
                        width: 1500px;
                    }
                }
            }
        }
    
        .home-footer-section {
            // grid-template-columns: auto;
            grid-gap: 10px;
            // grid-auto-flow: row;
    
            .card-fram {
                width: 380px;
                height: 180px;
            }
        }
    }
}

@media (max-width: 1350px) {
    .homeNew-container {
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 400px;
                height: 200px;
            }
        }
    }
}

@media (max-width: 1260px) {
    .homeNew-container {
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 380px;
                height: 200px;
            }
        }
    }
}

@media (max-width: 1240px) {
    .homeNew-container {
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 360px;
                height: 200px;
            }
        }
    }
}

@media (max-width: 1230px) {
    .homeNew-container {
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 350px;
                height: 200px;
            }
        }
    }
}

@media only screen and (max-width: 1210px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {

                .name {
                    width: 800px;
                    // background: red;
                }
            }
        }
    
        .home-footer-section {
            // grid-template-columns: auto;
            grid-gap: 10px;
            // grid-auto-flow: row;
    
            .card-fram {
                width: 360px;
                height: 200px;
            }
        }
    }
}

@media only screen and (max-width: 1155px) {
    .homeNew-container {
    
        .home-top-section{
    
            .front-image-and-details {

                .name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // background: red;
                }
            }
        }

        .home-footer-section {
    
            .card-fram {
                width: 340px;
                // height: 180px;
            }
        }
    }
}

@media(max-width: 1115px) {
    .homeNew-container {
    
        .home-footer-section {
            grid-template-columns: auto auto auto;
            grid-gap: 0;
    
            .card-fram {
                width: 340px;
                height: 200px;
    
                .card-header {
    
                    .nameOfTheCard {
    
                        p {

                        }
                    }
                }
    
                .card-body {
    
                    p {

                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1080px) {
    .homeNew-container {
        height: auto;

        // .home-top-section{
    
        //     .front-image-and-details {

        //         .name {
        //             // background: red;
                    
        //             img {
        //                 // width: 1000px;
        //             }
        //         }
        //     }
        // }
    
        .home-footer-section {
            grid-template-columns: auto;
            // grid-gap: 10px;
            grid-auto-flow: row;
            height: auto;
    
            .card-fram {
                width: 1000px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {

                .name {
                    width: 900px;
                    // background: red;
                    
                    img {
                        width: 800px;
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-template-columns: auto;
            grid-gap: 10px;
            grid-auto-flow: row;
    
            .card-fram {
                width: 950px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {
    
                .name {
                    // width: 100%;
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 10px;

            .card-fram {
                width: 900px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 915px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {
    
                .name {
                    width: 800px;
                    // background: red;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .home-footer-section {
    
            .card-fram {
                width: 850px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 892px) {
    .homeNew-container {

        .home-top-section{
            height: auto;
        }
    
        .home-footer-section {
    
            .card-fram {
                width: 800px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 860px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {
    
                .name {
                    width: 800px;
                    // background: red;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .home-footer-section {
    
            .card-fram {
                width: 800px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 815px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {
    
                .name {
                    width: 750px;
                    // background: red;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .home-footer-section {
    
            .card-fram {
                width: 750px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 760px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {

                .name {
                    width: 650px;
                    // background: red;

                    img {
                        width: 1200px;
                    }
                }
            }
        }
    
        .home-footer-section {
    
            .card-fram {
                width: 650px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 660px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {

                .name {
                    width: 800px;
                    // background: red;

                    img {
                        width: 500px;
                    }

                    h1 {
                        font-size: 20px;
                    }
                }
            }
        }
    
        .home-footer-section {
            padding-top: 10px;
            padding-bottom: 10px;

            .card-fram {
                width: 600px;
                height: 130px;
                margin:0;
            }
        }
    }
}

@media only screen and (max-width: 625px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {

                .name {
                    // width: 800px;
                    // background: red;

                    img {
                        // width: 100%;
                    }
                }

                .detail {

                    button {
                        // margin-top: 30px;
                    }
                }
            }
        }

        .home-footer-section {
    
            .card-fram {
                width: 550px;
                height: 130px;
            }
        }
    }
}

@media only screen and (max-width: 610px) {
    .homeNew-container {

    
        .home-top-section{
            height: auto;
    
            .front-image-and-details {

                .name {
                    // width: 800px;
                    // background: red;

                    img {
                        // width: 100%;
                    }
                }
    
                .detail {
                    display: grid;
    
                    .detail-left {
                        // background-color: red;
                        width: 100%;
                        margin-bottom: 20px;
                    }

                    .detail-right {
                        margin-top: 10px;
                        align-items: center;
                        justify-content: center;
                        width: 100%;

                        button {
                            margin-bottom: 50px;
                        }
                    }
                }
            }
        }


    }
}

@media (max-width: 560px) {
    .homeNew-container {


        .home-top-section{
    
            .front-image-and-details {

                .name {
                    // width: 750px;
                    // background: red;

                    img {
                        // width: 100%;
                    }
                }
    
                .detail {
    
                    .detail-right {
                        margin: 0;
    
                        button {
                            margin-top: 50px;
                            font-size: 10px;
                            width: 90px;
                            height: 70px;
                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                margin-top: 10px;
                width: 500px;
                height: 170px;
            }
        }
    }
}

@media only screen and (max-width: 510px) {
    .homeNew-container {

    
        .home-top-section{
    
            .front-image-and-details {

                .name {
                    width: 800px;
                    // height: 250px;
                    // background: red;

                    img {
                        width: 400px;
                    }

                    h1 {
                        font-size: 15px;
                    }
                }
    
                .detail {
    
                    .detail-right {
    
                        button {
                            margin-top: 50px;
                            font-size: 10px;
                            width: 90px;
                            height: 70px;
                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 450px;
                height: 170px;
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .homeNew-container {
    
        .home-top-section{
    
            .front-image-and-details {

                .name {
                    // width: 600px;
                    // height: 250px;
                    // background: red;

                    img {
                        // width: 100%;
                    }
                }
    
                .detail {
    
                    .detail-right {
    
                        button {
                            margin-top: 50px;
                            font-size: 10px;
                            width: 90px;
                            height: 70px;
                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 400px;
                height: 170px;
            }
        }
    }
}

@media only screen and (max-width: 410px) {
    .homeNew-container {
 
    
        .home-top-section{
            // background: red;
    
            .front-image-and-details {

                .name {
                    padding-top: 20%;
                    // width: 550px;
                    // height: 250px;
                    height: auto;
                    // background: red;

                    img {
                        width: 300px;
                    }
                }
    
                .detail {
                    
                    .detail-left {

                        .client {

                            .number {

                                h1 {
                                    font-size: 30px;
                                }
                            }
                        }

                        .workers {

                            h1 {
                                font-size: 30px;
                            }
                        }

                        .branches {

                            h1 {
                                font-size: 30px;
                            }
                        }
                    }
    
                    .detail-right {
    
                        button {
                            margin-top: 50px;
                            font-size: 10px;
                            width: 90px;
                            height: 70px;
                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 350px;
                height: 180px;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .homeNew-container {
    
        .home-top-section{
    
            .front-image-and-details {
                // background-color: red;

                .name {
                    // width: 500px;
                    // background: red;

                    img {
                        // width: 100%;
                    }
                }
    
                .detail {
    
                    .detail-right {
    
                        button {
                            margin-top: 50px;
                            font-size: 10px;
                            width: 90px;
                            height: 70px;
                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 350px;
                height: 180px;
            }
        }
    }
}

@media only screen and (max-width: 370px) {
    .homeNew-container {

        .home-top-section{
    
            .front-image-and-details {

                .name {
                    width: 500px;
                    // background: red;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .home-footer-section {

            .card-fram {
                width: 300px;
                height: 185px;
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .homeNew-container {
    
        .home-top-section{
    
            .front-image-and-details {

                .name {
                    width: 500px;
                    height: 200px;
                    // background: red;

                    img {
                        width: 100%;
                    }
                }
    
                .detail {
                    
                    .detail-left {

                        .client {

                            .number {

                                h1 {
                                    
                                    font-size: 30px;
                                }
                            }
                        }

                        .workers {

                            h1 {
                                font-size: 30px;
                            }
                        }

                        .branches {

                            h1 {
                                font-size: 30px;
                            }
                        }
                    }
    
                    .detail-right {
    
                        button {
                            margin-top: 50px;
                            font-size: 10px;
                            width: 90px;
                            height: 70px;
                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 350px;
                height: 170px;
            }
        }
    }
}

@media only screen and (max-width: 390px) {
    .homeNew-container {

    
        .home-top-section{
    
            .front-image-and-details {
                // background-color: red;

                .name {
                    // width: 500px;
                    // height: 200px;
                    // background: red;

                    img {
                        width: 300px;
                    }
                }
    
                .detail {

                    .detail-left {

                        .client {

                            .number {
                                h1 {
                                    font-size: 25px;
                                }
                            }

                        }

                        .workers {

                            .number {
                                h1 {
                                    font-size: 25px;
                                }
                            }

                        }

                        .branches {

                            .number {
                                h1 {
                                    font-size: 25px;
                                }
                            }

                        }
                    }
    
                    .detail-right {
    
                        button {
                            margin-top: 50px;
                            font-size: 10px;
                            width: 90px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 300px;
                height: 220px;
            }
        }
    }
}

@media only screen and (max-width: 365px) {
    .homeNew-container {

    
        .home-top-section{
    
            .front-image-and-details {
    
                .name {
                    // width: 450px;
                    // height: 200px;
                    // background: red;

                    img {
                        width: 280px;
                    }

                    h1 {
                        font-size: 10px;
                    }
                }

                .detail {

                    .detail-left {

                        .client {
                            width: 100px;

                        }

                        .workers {
                            width: 100px;

                        }

                        .branches {
                            width: 100px;

                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 300px;
                // height: 190px;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .homeNew-container {
    
        .home-top-section{
    
            .front-image-and-details {

                .name {
                    // width: 420px;
                    // height: 200px;
                    // background: red;

                    img {
                        // width: 100%;
                    }
                    font-size: 8px;
                }
    
                .detail {

                    .detail-left {

                        .client {
                            width: 100px;

                        }

                        .workers {
                            width: 100px;

                        }

                        .branches {
                            width: 100px;

                        }
                    }
                }
            }
        }
    
        .home-footer-section {
            grid-gap: 0;
    
            .card-fram {
                width: 300px;
                // height: 190px;
            }
        }
    }
}