*{
    margin: 0;
    padding: 0;
}

.navigation-container {
    position: fixed;
    display: flex;
    position: fixed;
    width: 100%;
    height: 10vh;
    // background-color: rgb(41, 37, 37);
    // background: rgb(42, 91, 255);
    z-index: 2;

    nav {
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        // background-color: rgb(41, 37, 37);
        background: rgb(14, 29, 52, 0.9);
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

        .check {
            display: none;
        }

        label {

            .btn, .cancel {
                margin-top: 18px;
                display: flex;
                color: white;
                font-size: 25px;
                float: right;
                line-height: 60px;
                margin-right: 40px;
                cursor: pointer;
                display: none;
            }
        }

        .name {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 2%;
            // top: 15px;
            color: white;
            // background-color: red;
            height: 90%;

            img {
                padding-right: 20px;
                height: 50px;

                a {
                    background: white;
                }
            }

            h1 {
                font-size: 20px;
            }
        }

        ul {
            float: right;
            margin-right: 25px;

            li {
                display: inline-block;
                // line-height: 60px;
                margin: 0 15px;

                a {
                    position: relative;
                    color: white;
                    font-size: 15px;
                    text-transform: uppercase;
                    border-radius: 10px;
                    padding: 5px 0;

                    &:before {
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: 0;
                        height: 3px;
                        width: 100%;
                        background: white;
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform .2s linear;
                    }

                    &:hover:before {
                        transform: scaleX(1);
                        transform-origin: left;
                    }
                }
            }
        }
    }
}

@media (max-width:825px) {
    .navigation-container {

        nav {
            background: rgb(14, 29, 52);
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            .check {

                &:checked ~ ul{
                    right: 0;
                }

                &:checked ~ label .btn{
                    display: none;
                }

                &:checked ~ label .cancel{
                    display: block;
                }
            }

            .name {
                // top: 8px;
                .logo {

                    img {
                        height: 35px;
                    }
                }
            }

            label {

                .btn, .cancel {
                    line-height: 0;
                    margin-top: 0;
                    margin-right: 10px;
                }

                .btn {
                    // margin-top: 12px;
                    display: block;
                }
            }

            ul {
                position: fixed;
                width: 40%;
                height: 100vh;
                // background: rgb(41, 37, 37);
                background: rgb(14, 29, 52);
                top: 60px;
                right: -100%;
                text-align: center;
                transition: all .5s;
                margin-right: 0;

                li {
                    display: block;
                    margin: 50px 0;
                    line-height: 30px;
                }
            }
        }
    }
}