.contactUs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100wh;
    // height: 100vh;
    padding-top: 10px;

    .contactUs-top {
        display: flex;
        width: 100%;
        // height: 50vh;
        height: auto;
        // background-color: aliceblue;

        .contactUs-top-left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: 100%;
            // background-color: rgb(189, 142, 81);

            img {
                width: 75%;
                height: 100%;
                background-position: center;
                background-size: cover;
            }
        }

        .contactUs-top-right {
            display: flex;
            flex-direction: column;
            width: 60%;
            height: 100%;
            height: auto;
            // background-color: rgb(83, 70, 52);
            padding: 20px;

            .contactUs-top-right-header {
                display: flex;
                align-items: center;
                // justify-content: center;
                width: 100%;
                height: 10vh;
                // background-color: red;

                p {
                    font-weight: bold;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    font-size: 25px;
                    color: rgb(13, 13, 41);
                }
            }

            .contactUs-top-right-body {
                display: flex;
                align-items: center;
                // justify-content: center;
                width: 100%;
                // background-color: aquamarine;

                p {
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    width: 80%;
                    text-align: justify;
                }
            }

            button {
                width: 150px;
                height: 40px;
                background-color: #006FB4;
                color: white;
                border-radius: 10px;
                margin-top: 20px;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    .contactUs-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        // height: 45vh;
        height: auto;
        background-color: #101721;
        grid-gap: 10px;
        overflow-x: hidden;
        padding-top: 10px;

        .conatct-left {
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 28%;
            height: 100%;
            // background-color: yellow;
            // margin: 10px;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

            .colz-icon {
                display: flex;
                font-size: 25px;
                color: white;
                
                a {
                    padding: 5px;

                    &:hover {
                        transform: scale(1.5);
                    }
                }
            }

            h1 {
                justify-content: flex-start;
                font-size: 20px;
                font-weight: bold;
                color: white;
            }

            p {
                color: white;
            }

            .contact-detail {
                display: flex;
                // flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                width: 300px;
                height: 120px;
                border: solid white 2px;
                padding: 15px;

                .detail-left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 30%;
                    height: 100%;
                    // background-color: yellow;

                    p {
                        padding: 8px;
                        font-size: 12px;
                    }
                }

                .detail-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 70%;
                    height: 100%;
                    // background-color: greenyellow;

                    p {
                        font-size: 12px;
                        padding: 8px;
                    }
                }
            }
        }

        .contact-center {
            display: flex;
            flex-direction: column;
            width: 34%;
            height: 100%;
            // background-color: greenyellow;
            // margin: 10px;

            .contact-center-top {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 90%;
                // background-color: yellow;

                .centerPart-left {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 28%;
                    height: 100%;
                    // background-color: red;

                    h1 {
                        font-size: 15px;
                        color: white;
                        font-weight: bold;

                    }

                    a {
                        font-size: 12px;
                        color: white;
                        margin: 6px;
                        display: flex;
                        align-items: flex-start;
                    }
                }
    
                .centerPart-center {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 34%;
                    height: 100%;
                    // background-color: rgb(180, 128, 128);

                    h1 {
                        font-size: 15px;
                        align-items: flex-start;
                        color: white;
                        font-weight: bold;
                    }

                    a {
                        font-size: 12px;
                        color: white;
                        margin: 6px;
                        display: flex;
                        align-items: flex-start;
                    }
                }
    
                .centerPart-right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                    width: 38%;
                    height: 100%;
                    // background-color: rgb(80, 50, 50);

                    h1 {
                        font-size: 15px;
                        align-items: flex-start;
                        color: white;
                        font-weight: bold;
                    }

                    p {
                        font-size: 12px;
                        color: white;
                        margin: 6px;
                        display: flex;
                        align-items: flex-start;
                        text-align: left;
                    }
                }

            }
        }
        
        .contact-right {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28%;
            height: 100%;
            // background-color: yellow;
            // margin: 10px;

            .map-fram {
                padding: 5px;
                width: 250px;
                height: 250px;
                border:solid white 2px;
                // background-color: red;

                iframe {
                    width:235px;
                    height:235px;
                }
            }
        }
    }

    .conatct-center-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 10%;
        // background-color: greenyellow;

        p {
            font-size: 12px;
            color: white;
        }
    }
}

@media (max-width:1239px) {
    .contactUs-container {
    
        .contactUs-top { 
            height: 60vh;
         }

        .contactUs-bottom {
            // height: 37vh;
            height: auto;
            display: grid;
            grid-template-columns: auto auto auto;
    
            .conatct-left {
                width: 350px;
                // background-color: #006FB4;
            }
    
            .contact-center {
                width: 350px;
                // background-color: red;
            }
            
            .contact-right {
                width: 350px;
                // background-color: yellow;
            }
        }
    }
}

@media (max-width:1115px) {
    .contactUs-container {

        .contactUs-top {
            height: auto;

            .contactUs-top-left {

                img {
                    width: 100%;
                }
            }
        }

        .contactUs-bottom {
            display: grid;
            grid-template-columns: auto auto auto;
    
            .conatct-left {
                width: 350px;
                // background-color: #006FB4;
            }
    
            .contact-center {
                width: 350px;
                // background-color: red;
            }
            
            .contact-right {
                width: 350px;
                // background-color: yellow;
            }
        }
    }
}

@media (max-width:1040px) {
    .contactUs-container {
    
        .contactUs-top {
    
            .contactUs-top-left {
                width: 50%;
            }
    
            .contactUs-top-right {
                width: 50%;
    
                .contactUs-top-right-body {
    
                    p {
                        width: 95%;
                    }
                }
            }
        }
    
        .contactUs-bottom {
            display: grid;
            grid-template-columns: auto auto auto;
            // row-gap: 50px;

            .conatct-left {
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 325px;
                height: 100%;
                // background-color: yellow;
                margin: 10px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    
                .colz-icon {
                    display: flex;
                    font-size: 25px;
                    color: white;
                    
                    a {
                        padding: 5px;
    
                        &:hover {
                            transform: scale(1.5);
                        }
                    }
                }
    
                h1 {
                    justify-content: flex-start;
                    font-size: 20px;
                    font-weight: bold;
                    color: white;
                }
    
                p {
                    color: white;
                }
    
                .contact-detail {
                    display: flex;
                    // flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    width: 300px;
                    height: 120px;
                    border: solid white 2px;
                    padding: 15px;
    
                    .detail-left {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        width: 30%;
                        height: 100%;
                        // background-color: yellow;
    
                        p {
                            padding: 8px;
                            font-size: 12px;
                        }
                    }
    
                    .detail-right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        width: 70%;
                        height: 100%;
                        // background-color: greenyellow;
    
                        p {
                            font-size: 12px;
                            padding: 8px;
                        }
                    }
                }
            }
    
            .contact-center {
                display: flex;
                flex-direction: column;
                width: 20px;
                height: 100%;
                // background-color: greenyellow;
                // margin: 10px;
    
                .contact-center-top {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 90%;
                    // background-color: yellow;
    
                    .centerPart-left {
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 28%;
                        height: 100%;
                        // background-color: red;
    
                        h1 {
                            font-size: 15px;
                            color: white;
                            font-weight: bold;
    
                        }
    
                        a {
                            font-size: 12px;
                            color: white;
                            margin: 6px;
                            display: flex;
                            align-items: flex-start;
                        }
                    }
        
                    .centerPart-center {
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 34%;
                        height: 100%;
                        // background-color: rgb(180, 128, 128);
    
                        h1 {
                            font-size: 15px;
                            align-items: flex-start;
                            color: white;
                            font-weight: bold;
                        }
    
                        a {
                            font-size: 12px;
                            color: white;
                            margin: 6px;
                            display: flex;
                            align-items: flex-start;
                        }
                    }
        
                    .centerPart-right {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 10px;
                        width: 38%;
                        height: 100%;
                        // background-color: rgb(80, 50, 50);
    
                        h1 {
                            font-size: 15px;
                            align-items: flex-start;
                            color: white;
                            font-weight: bold;
                        }
    
                        p {
                            font-size: 12px;
                            color: white;
                            margin: 6px;
                            display: flex;
                            align-items: flex-start;
                            text-align: left;
                        }
                    }
    
                }
    
                .conatct-center-bottom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 10%;
                    // background-color: greenyellow;
    
                    p {
                        font-size: 12px;
                        color: white;
                    }
                }
            }
            
            .contact-right {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 325px;
                height: 100%;
                // background-color: yellow;
                margin: 10px;
    
                .map-fram {
                    padding: 5px;
                    width: 250px;
                    height: 250px;
                    border:solid white 2px;
                    // background-color: red;
                }
            }
        }
    }
}

@media (max-width:1070px) {
    .contactUs-container {
    
        .contactUs-bottom {
            height: auto;
            display: grid;
            grid-template-columns: auto auto;
    
            .conatct-left {
                width: 450px;
                // background-color: #006FB4;
            }
    
            .contact-center {
                width: 450px;
                // background-color: red;
            }
            
            .contact-right {
                width: 550px;
    
                .map-fram {
                    width: 530px;
    
                    iframe {
                        width:515px;
                    }
                }
            }
        }
    }
}

@media (max-width:1035px) {
    .contactUs-container {

        .contactUs-bottom {
            width: 100%;
    
            .contact-center {
                width: 420px;
                // background-color: red;
            }
        }
    }
}

@media (max-width:1005px) {
    .contactUs-container {

        .contactUs-bottom {
            width: 100%;
    
            .contact-center {
                width: 400px;
                // background-color: red;
            }
        }
    }
}

@media (max-width:1000px) {
    .contactUs-container {
    
        .contactUs-bottom {
    
            .conatct-left {
                width: 550px;
                // background-color: #006FB4;
            }
    
            .contact-center {
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 550px;
                // background-color: red;
                padding-left: 0;
                padding-right: 100px;
            }
            
            .contact-right {
                width: 550px;
                // background-color: yellow;

                .map-fram {
                    // width: 530px;
                    // margin-left: 60px;
                }
            }
        }
    }
}

@media (max-width:980px) {
    .contactUs-container {
        overflow-x: hidden;
    
        .contactUs-bottom {
            grid-template-columns: auto;
    
            .conatct-left {
                width: 550px;
                // background-color: #006FB4;
            }
    
            .contact-center {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 550px;
                // background-color: red;
                padding-left: 60px;
                padding-right: 0;
            }
            
            .contact-right {
                width: 550px;
                // background-color: yellow;

                .map-fram {
                    // width: 530px;
                    margin-left: 10px;
                }
            }
        }
    }
}

@media (max-width:892px) {
    .contactUs-container {
        
        .contactUs-top {
            height: auto;
        }

    }
}

@media (max-width:820px) {
    .contactUs-container {
        
        .contactUs-top {
            display: flex;
            width: 100%;
            // height: 45vh;
            // background-color: aliceblue;
            background-image: url('../../images/aireplane2.png');
            background-position: center;
            background-size: cover;
    
            .contactUs-top-left {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 0;
                height: 100%;
                // background-color: rgb(189, 142, 81);
    
                img {
                    visibility: hidden;
                }
            }
    
            .contactUs-top-right {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                // background-color: rgb(83, 70, 52);
                padding: 20px;
    
                .contactUs-top-right-body {
    
                    p {
                        width: 100%;
                    }
                }
            }
        }

    }
}

@media (max-width:640px) {
    .contactUs-container {

        .contactUs-top {
    
            .contactUs-top-right {
    
                .contactUs-top-right-body {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    p {
                        width: 90%;
                    }
                }
            }
        }
    
        .contactUs-bottom {
            grid-template-columns: auto;
    
            .conatct-left {
                width: 550px;
                // background-color: #006FB4;
            }
    
            .contact-center {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 550px;
                // background-color: red;
                padding-left: 50px;
                padding-right: 0;
            }
            
            .contact-right {
                width: 550px;
                // background-color: yellow;

                .map-fram {
                    // width: 530px;
                    margin-left: 20px;
                }
            }
        }
    }
}

@media (max-width:575px) {
    .contactUs-container {
        width: 100%;
    
        .contactUs-bottom {
            grid-template-columns: auto;
    
            .conatct-left {
                width: 550px;
                // background-color: #006FB4;
            }
    
            .contact-center {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 550px;
                // background-color: red;
                padding-left: 50px;
                padding-right: 0;
            }
            
            .contact-right {
                width: 550px;
                // background-color: yellow;

                .map-fram {
                    // width: 530px;
                    margin-left: 2px;
                }
            }
        }
    }
}

@media (max-width:560px) {
    .contactUs-container {
    
        .contactUs-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .contact-right {
                width: 550px;
    
                .map-fram {
                    width: 90%;
                    margin-left: 2px;
    
                    iframe {
                        width:480px;
                    }
                }
            }
        }
    }
}

@media (max-width:500px) {
    .contactUs-container {
        width: 100%;
    
        .contactUs-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .contact-right {
    
                .map-fram {
                    width: 80%;
                    margin-left: 2px;
    
                    iframe {
                        width:425px;
                    }
                }
            }
        }
    }
}

@media (max-width:450px) {
    .contactUs-container {
    
        .contactUs-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
    
            .contact-center {
                width: 100%;
            }
            
            .contact-right {
                width: 550px;
    
                .map-fram {
                    width: 75%;
                    margin-left: 2px;
    
                    iframe {
                        width:399px;
                    }
                }
            }
        }
    }
}

@media (max-width:430px) {
    .contactUs-container {

        .contactUs-top {
            height: auto;
        }
    
        .contactUs-bottom {

            .contact-right {
    
                .map-fram {
                    width: 65%;
                    margin-left: 2px;
    
                    iframe {
                        width:342px;
                    }
                }
            }
        }
    }
}

@media (max-width:370px) {
    .contactUs-container {
    
        .contactUs-bottom {

            .contact-center {
                display: flex;
                flex-direction: column;
                align-items: center;
                // background-color: greenyellow;
                // margin: 10px;
                padding-left: 2px;
            }

            .contact-right {
    
                .map-fram {
                    width: 55%;
                    margin-left: 2px;
    
                    iframe {
                        width:289px;
                    }
                }
            }
        }
    }
}