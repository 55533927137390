.packageNew-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // background-color: beige;

    .package-header {

        h1 {
            padding-top: 2vh;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-size: 35px;
            font-weight: bold;
        }
    }

    .packagecard-section {
        margin: 50px;
        display: grid;
        background-color: transparent;
        grid-template-rows: auto auto;
        grid-auto-flow: row;
        grid-gap: 50px;
        // background-color: yellow;

        .package-card-fram {
            width: 1150px;
            height: 300px;
            // background-color: greenyellow;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // background-color: #0b1c27;
            background-color: rgba(255, 255, 255, 0.6);
            transition: 0.3s ease all;
            border-radius: 10px;
            overflow: hidden;
            backdrop-filter: blur(2px);
            box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

            &:hover {
                transition: cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            .package-left {
                width: 40%;

                img{
                    width:100%;
                    height: auto;
                    
                    &:hover {
                        transform: scale(1.04);
                    }
                }   
            }

            .package-right {
                display: flex;
                flex-direction: column;
                width: 60%;
                height: 262px;
                // border: solid #0b1c27 2px;
                // background-color: red;
                align-items: center;
                overflow: auto;
                text-align: justify;
                margin-bottom: 8px;

                h1 {
                    padding-top: 10px;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    font-weight: bold;
                    // color: white;
                }    

                p {
                    // color: white;
                    width: 95%;
                    height: 85%;
                    font-weight: 100;
                    // background-color: red;
                    overflow: auto;
                }
            }
        }
    }
}

@media (max-width: 1160px) {
    .packageNew-container {
    
        .package-header {
    
            h1 {

            }
        }
    
        .packagecard-section {

    
            .package-card-fram {
                width: 1100px;
                height: 300px;
    
                .package-left {

    
                    img{

                    }   
                }
    
                .package-right {
    
                    h1 {

                    }    
    
                    p {
                        height: 90%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1115px) {
    .packageNew-container {
    
        .package-header {
    
            h1 {

            }
        }
    
        .packagecard-section {

    
            .package-card-fram {
                width: 1000px;
                height: 274px;
    
                .package-left {

    
                    img{

                    }   
                }
    
                .package-right {
    
                    h1 {

                    }    
    
                    p {
                        height: 80%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1030px) {
    .packageNew-container {
    
        .package-header {
    
            h1 {

            }
        }
    
        .packagecard-section {

    
            .package-card-fram {
                width: 950px;
                height: 260px;
    
                .package-left {

    
                    img{

                    }   
                }
    
                .package-right {
    
                    h1 {

                    }    
    
                    p {

                    }
                }
            }
        }
    }
}

@media (max-width: 980px) {
    .packageNew-container {
    
        .package-header {
    
            h1 {

            }
        }
    
        .packagecard-section {
            grid-template-columns: auto auto;
    
            .package-card-fram {
                background-color: rgba(255, 255, 255, 0.6);
                flex-direction: column;
                width: 336px;
                height: 500px;
    
                .package-left {
                    width: 100%;
    
                    img{

                    }   
                }
    
                .package-right {
                    width: 95%;
    
                    h1 {

                    }    
    
                    p {
                        // width: ;
                    }
                }
            }
        }
    }
}

@media (max-width: 740px) {
    .packageNew-container {
    
        .package-header {
    
            h1 {

            }
        }
    
        .packagecard-section {
            grid-template-columns: auto auto;
    
            .package-card-fram {
                width: 250px;
                height: 400px;
    
                .package-left {
    
                    img{

                    }   
                }
    
                .package-right {
    
                    h1 {

                    }    
    
                    p {

                    }
                }
            }
        }
    }
}

@media (max-width: 560px) {
    .packageNew-container {
    
        .packagecard-section {
            grid-template-columns: auto auto;
            grid-gap: 10px;
    
            .package-card-fram {
                width: 250px;
                height: 400px;
            }
        }
    }
}

@media (max-width: 520px) {
    .packageNew-container {
    
        .packagecard-section {
            grid-template-columns: auto;
            grid-gap: 10px;
    
            .package-card-fram {
                width: 450px;
                height: 500px;
            }
        }
    }
}

@media (max-width: 460px) {
    .packageNew-container {
    
        .packagecard-section {
    
            .package-card-fram {
                width: 400px;
                height: 500px;
            }
        }
    }
}

@media (max-width: 410px) {
    .packageNew-container {
    
        .packagecard-section {
    
            .package-card-fram {
                width: 350px;
                height: 500px;
            }
        }
    }
}

@media (max-width: 360px) {
    .packageNew-container {
    
        .packagecard-section {
    
            .package-card-fram {
                width: 300px;
                height: 450px;
            }
        }
    }
}

@media (max-width: 310px) {
    .packageNew-container {
    
        .packagecard-section {
    
            .package-card-fram {
                width: 250px;
                height: 450px;
            }
        }
    }
}