.getquoteform-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.575);
    position: fixed;
    top: 0;
    z-index: 10;

    .card-fram {
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        width: 38%;
        // height: 90vh;
        height: auto;
        background-color: rgba(255, 255, 255, 0.705);
        border: solid #006FB4 2px;
        margin-right: 1%;
        margin-left: 1%;
        z-index: 1;
        border-radius: 15px;
        backdrop-filter: blur(2px);

        .card-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
            // background-color: rgb(248, 199, 199);
            border-radius: 15px 15px 0 0;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-size: 25px;
            font-weight: bold;
            color: #006FB4;
        }

        .card-body {
            width: 100%;
            height: 530px;
            // border-radius: 0 0 15px 15px;
            // background-color: antiquewhite;
            overflow-y: auto;

            .countries-input {
                width: 100%;
                height: 100px;
                // background-color: red;
                margin-top: 2px;

                .requested-feilds {
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    width: 100%;
                    height: 35%;
                    background-color: #006FB4;
    
                    h1 {
                        color: white;
                        font-weight: bold;
                        padding-left: 10px;
                    }
                }

                .request-input-feilds {
                    align-items: center;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-auto-flow: row;
                    grid-gap: 5px;
                    height: 65%;

                    .request-input-feilds-from {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding-left: 20px;

                        input {
                            padding-left: 10px;
                            width: 90%;
                            height: 35px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
                    }

                    .request-input-feilds-to {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding-left: 20px;
                        // background-color: greenyellow;

                        input {
                            width: 90%;
                            height: 35px;
                            border-radius: 6px;
                            border: 1px solid rgb(219, 219, 219);
                        }
                    }
                }
            }

            .customer-detail {
                width: 100%;
                height: 170px;
                // background-color: rgb(0, 255, 106);

                .customer-information {
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    width: 100%;
                    height: 20%;
                    background-color: #006FB4;
    
                    h1 {
                        color: white;
                        font-weight: bold;
                        padding-left: 10px;
                    }
                }

                .customer-input-feilds {
                    align-items: center;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-auto-flow: row;
                    grid-gap: 5px;
                    height: 80%;

                    .customer-detail-input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding-left: 20px;

                        input {
                            padding-left: 10px;
                            width: 90%;
                            height: 35px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
                    }
                }
            }

            .package-detail {
                width: 100%;
                height: 200px;
                // background-color: rgb(176, 238, 77);
                margin-top: 2px;
                margin-bottom: 10px;

                .package-information {
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    width: 100%;
                    height: 20%;
                    background-color: #006FB4;

                    h1 {
                        color: white;
                        font-weight: bold;
                        padding-left: 10px;
                    }
                }

                .package-input-feilds {
                    align-items: center;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-auto-flow: row;
                    grid-gap: 5px;
                    height: 85%;

                    .package-detail-input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding-left: 20px;

                        input {
                            padding-left: 10px;
                            width: 90%;
                            height: 35px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
                    }
                }
            }

            .optional-detail {
                width: 100%;
                height: 100px;
                // background-color: rgb(176, 238, 77);
                margin-top: 2px;

                .optional-information {
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    width: 100%;
                    height: 35%;
                    background-color: #006FB4;

                    h1 {
                        color: white;
                        font-weight: bold;
                        padding-left: 10px;
                    }
                }

                .optional-input-feilds {
                    align-items: center;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-auto-flow: row;
                    grid-gap: 5px;
                    height: 65%;

                    .optional-detail-feilds {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding-left: 20px;

                        input {
                            padding-left: 10px;
                            width: 90%;
                            height: 35px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
                    }
                }
            }

            .quote-footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                height: 70px;
                // background-color: yellow;
                border-radius: 0 0 15px 15px;

                .quote-submit {
                    width: 20%;
                    height: 40px;
                    background-color: #006FB4;
                    border-radius: 6px;
                    color: white;
                    font-weight: bold;
                }

                .quote-cancel {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20%;
                    height: 40px;
                    background-color: #525252;
                    border-radius: 6px;
                    color: white;
                    font-weight: bold;
                    margin-left: 10px;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width:1238.89px) {
    .getquoteform-container {

        .card-fram {
            width: 45%;

            .card-title {

            }

            .card-body {

                .countries-input {

                }

                .customer-detail {

                }

                .package-detail {

                }

                .optional-detail {

                }

                .quote-footer {

                }
            }
        }
    }
}

@media (max-width:1110px) {
    .getquoteform-container {

        .card-fram {
            width: 50%;
        }
    }
}

@media (max-width:960px) {
    .getquoteform-container {

        .card-fram {
            width: 60%;
        }
    }
}

@media (max-width:850px) {
    .getquoteform-container {

        .card-fram {
            width: 65%;
        }
    }
}

@media (max-width:700px) {
    .getquoteform-container {

        .card-fram {
            width: 70%;
        }
    }
}

@media (max-width:720px) {
    .getquoteform-container {

        .card-fram {
            width: 75%;
        }
    }
}

@media (max-width:620px) {
    .getquoteform-container {

        .card-fram {
            width: 80%;
        }
    }
}

@media (max-width:550px) {
    .getquoteform-container {

        .card-fram {
            width: 85%;
        }
    }
}

@media (max-width:500px) {
    .getquoteform-container {

        .card-fram {
            width: 90%;
        }
    }
}

@media (max-width:460px) {
    .getquoteform-container {

        .card-fram {
            width: 95%;
        }
    }
}

@media (max-width:450px) {
    .getquoteform-container {

        .card-fram {
            height: auto;

            .card-title {

            }

            .card-body {

                .countries-input {
                    height: 200px;

                    .requested-feilds {
                        height: 25%;
                    }

                    .request-input-feilds {
                        height: 65%;
                        grid-template-columns: auto;  
                    }
                }

                .customer-detail {
                    height: 320px;

                    .customer-information {
                        height: 15%;
                    }
                    
                    .customer-input-feilds {
                        height: 84%;
                        grid-template-columns: auto;  
                    }
                }

                .package-detail {
                    height: 350px;

                    .package-information {
                        height: 15%;
                    }

                    .package-input-feilds {
                        height: 88%;
                        grid-template-columns: auto;  
                    }
                }

                .optional-detail {
                    height: 150px;
                    // background: red;

                    .optional-information {
                        height: 35%;
                    }

                    .optional-input-feilds {
                        height: 70%;
                        grid-template-columns: auto;  
                    }
                }

                .quote-footer {

                }
            }
        }
    }
}

@media (max-width:320px) {
    .getquoteform-container {

        .card-fram {

            .card-body {

                .package-detail {
                    width: 100%;
                    height: 200px;
                    // background-color: rgb(176, 238, 77);
                    margin-top: 2px;
                    margin-bottom: 10px;
    
                    .package-information {
                        display: flex;
                        align-items: center;
                        // justify-content: center;
                        width: 100%;
                        height: 20%;
                        background-color: #006FB4;
    
                        h1 {
                            color: white;
                            font-weight: bold;
                            padding-left: 10px;
                        }
                    }
    
                    .package-input-feilds {
                        align-items: center;
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-auto-flow: row;
                        grid-gap: 5px;
                        height: 85%;
    
                        .package-detail-input {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            padding-left: 20px;
                        }
                    }
                }
            }

            .quote-footer {
                // background-color: yellow;

                .quote-submit {
                    width: 60px;
                    height: 40px;
                }

                .quote-cancel {
                    width: 20%;
                    height: 40px;
                }
            }
        }
    }
}