.ContactMe-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.575);
    // background-color: white;
    position: fixed;
    top: 0;
    z-index: 10;

    h1 {
        font-size: 30px;
    }

    .contactMe-parent {
        display: flex;
        width: 60%;
        // height: 65vh;
        height: auto;
        background-color: #8f98dd;
        border-radius: 20px;

        .contactForm-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 600px;
            height: 100%;
            background-color: #23263b;
            // background: red;
            color: white;
            padding: 25px;
            border-radius: 20px 0px 0px 20px;

            h1 {
                font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
            }

            .colz {
                margin-bottom: 40px;

                .colz-icon {
                    display: flex;

                    a {
                        font-size: 20px;
                        color: white;
                        padding: 8px;
                        padding-left: 0px;
    
                        &:hover {
                            transform: scale(1.5);
                        }
                    }
                }
                
            }

            .image {

                img {
                    width: 550px;
                    height: 200px;
                    opacity: 0.4;
                }
            }
        }

        .contactForm-right {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 8px;
            padding-right: 8px;
            width: 60%;
            height: 100%;
            background-color: #23263b;
            border-radius: 0px 20px 20px 0px;

            .form-top {
                padding: 20px;
                display: flex;
                flex-direction: column;
                border-radius: 20px 20px 20px 20px;
                width: 98%;
                height: 360px;
                background-color: rgb(255, 255, 255);

                .input-field {
                    display: flex;
                    flex-direction: column;
                    text-align: start;

                    input {
                        padding-left: 5px;
                        margin-bottom: 10px;
                        height: 35px;
                        border-radius: 10px;
                        border: 1px solid rgb(219, 219, 219);
                    }

                    textarea {
                        padding-left: 5px;
                        margin-bottom: 10px;
                        border-radius: 10px;
                        border: 1px solid rgb(219, 219, 219);
                    }

                    label {
                        margin: 0;
                    }
                }

                .submit-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 120px;
                    height: 40px;
                    background-color: #23263b;
                    color: white;
                    border-radius: 20px;

                    &:hover {
                        border: solid var(--dark-orange) 2px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.no-scroll {
    overflow: hidden;
  }
  
@media (max-width:1239px) {
    .ContactMe-container {
        .contactMe-parent {
            width: 80%;
            .contactForm-right {

            }
        }
    }    
}

@media (max-width: 1035px) {
    .ContactMe-container {
        width: 100%;
        // background-color: red;
    
        .contactMe-parent {
            display: flex;
            width: 90%;
            height: 80vh;
            background-color: #8f98dd;
            border-radius: 20px;
    
            .contactForm-left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 600px;
                height: 100%;
                background-color: #23263b;
                color: white;
                padding: 25px;
                border-radius: 20px 0px 0px 20px;
    
                h1 {
                    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
                }
    
                .colz {
                    margin-bottom: 80px;
    
                    .colz-icon {
                        display: flex;
    
                        a {
                            font-size: 20px;
                            color: white;
                            padding: 8px;
                            padding-left: 0px;
        
                            &:hover {
                                transform: scale(1.5);
                            }
                        }
                    }
                    
                }
    
                .image {
    
                    img {
                        width: 550px;
                        height: 200px;
                        opacity: 0.4;
                    }
                }
            }
    
            .contactForm-right {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-bottom: 8px;
                padding-right: 8px;
                width: 500px;
                height: 100%;
                background-color: #23263b;
                border-radius: 0px 20px 20px 0px;
    
                .form-top {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    border-radius: 20px 20px 20px 20px;
                    width: 98%;
                    height: 400px;
                    background-color: rgb(255, 255, 255);
    
                    .input-field {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
    
                        input {
                            margin-bottom: 10px;
                            height: 35px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
    
                        textarea {
                            margin-bottom: 10px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
    
                        label {
                            margin: 0;
                        }
                    }
    
                    .submit-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        height: 40px;
                        background-color: #23263b;
                        color: white;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1020px) {
    .ContactMe-container {
    
        .contactMe-parent {
            display: flex;
            width: 90%;
            height: 80vh;
            background-color: #8f98dd;
            border-radius: 20px;
    
            .contactForm-left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 600px;
                height: 100%;
                background-color: #23263b;
                color: white;
                padding: 25px;
                border-radius: 20px 0px 0px 20px;
    
                h1 {
                    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
                }
    
                .colz {
                    margin-bottom: 80px;
    
                    .colz-icon {
                        display: flex;
    
                        a {
                            font-size: 20px;
                            color: white;
                            padding: 8px;
                            padding-left: 0px;
        
                            &:hover {
                                transform: scale(1.5);
                            }
                        }
                    }
                    
                }
    
                .image {
    
                    img {
                        width: 550px;
                        height: 200px;
                        opacity: 0.4;
                    }
                }
            }
    
            .contactForm-right {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-bottom: 8px;
                padding-right: 8px;
                width: 500px;
                height: 100%;
                background-color: #23263b;
                border-radius: 0px 20px 20px 0px;
    
                .form-top {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    border-radius: 20px 20px 20px 20px;
                    width: 98%;
                    height: 400px;
                    background-color: rgb(255, 255, 255);
    
                    .input-field {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
    
                        input {
                            margin-bottom: 10px;
                            height: 35px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
    
                        textarea {
                            margin-bottom: 10px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
    
                        label {
                            margin: 0;
                        }
                    }
    
                    .submit-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        height: 40px;
                        background-color: #23263b;
                        color: white;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 920px) {
    .ContactMe-container {
    
        .contactMe-parent {
            display: flex;
            width: 90%;
            height: 85vh;
            background-color: #8f98dd;
            border-radius: 20px;
    
            .contactForm-left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 600px;
                height: 100%;
                background-color: #23263b;
                color: white;
                padding: 25px;
                border-radius: 20px 0px 0px 20px;
    
                h1 {
                    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
                }
    
                .colz {
                    margin-bottom: 80px;
    
                    .colz-icon {
                        display: flex;
    
                        a {
                            font-size: 20px;
                            color: white;
                            padding: 8px;
                            padding-left: 0px;
        
                            &:hover {
                                transform: scale(1.5);
                            }
                        }
                    }
                    
                }
    
                .image {
    
                    img {
                        width: 350px;
                        height: 150px;
                        opacity: 0.4;
                    }
                }
            }
    
            .contactForm-right {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-bottom: 8px;
                padding-right: 8px;
                width: 600px;
                height: 100%;
                background-color: #23263b;
                border-radius: 0px 20px 20px 0px;
    
                .form-top {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    border-radius: 20px 20px 20px 20px;
                    width: 100%;
                    height: 380px;
                    background-color: rgb(255, 255, 255);
    
                    .input-field {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
    
                        input {
                            margin-bottom: 10px;
                            height: 35px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
    
                        textarea {
                            margin-bottom: 10px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
    
                        label {
                            margin: 0;
                        }
                    }
    
                    .submit-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        height: 40px;
                        background-color: #23263b;
                        color: white;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 912px) {
    .ContactMe-container {
    
        .contactMe-parent {
    
            height: auto;
        }
    }
}

@media (max-width: 820px) {
    .ContactMe-container {
    
        .contactMe-parent {
            display: flex;
            width: 90%;
            // height: 80vh;
            height: auto;
            background-color: #8f98dd;
            border-radius: 20px;
    
            .contactForm-left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 400px;
                height: 100%;
                background-color: #23263b;
                color: white;
                padding: 25px;
                border-radius: 20px 0px 0px 20px;
    
                h1 {
                    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
                }
    
                .colz {
                    margin-bottom: 80px;
    
                    .colz-icon {
                        display: flex;
    
                        a {
                            font-size: 20px;
                            color: white;
                            padding: 8px;
                            padding-left: 15px;
        
                            &:hover {
                                transform: scale(1.5);
                            }
                        }
                    }
                    
                }
    
                .image {
    
                    img {
                        width: 350px;
                        height: 150px;
                        opacity: 0.4;
                        visibility: hidden;
                    }
                }

                h6 {
                    visibility: hidden;
                }
            }
    
            .contactForm-right {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-bottom: 8px;
                padding-right: 8px;
                width: 1000px;
                height: 100%;
                background-color: #23263b;
                border-radius: 0px 20px 20px 0px;
    
                .form-top {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    border-radius: 20px 20px 20px 20px;
                    width: 100%;
                    height: 380px;
                    background-color: rgb(255, 255, 255);
    
                    .input-field {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
    
                        input {
                            margin-bottom: 10px;
                            height: 35px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
    
                        textarea {
                            margin-bottom: 10px;
                            border-radius: 10px;
                            border: 1px solid rgb(219, 219, 219);
                        }
    
                        label {
                            margin: 0;
                        }
                    }
    
                    .submit-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        height: 40px;
                        background-color: #23263b;
                        color: white;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 630px) {
    .ContactMe-container {
    
        .contactMe-parent {
            background-color: transparent;
    
            .contactForm-left {
                width: 0;
                padding: 0;
    
                h1 {
                    visibility: hidden;
                }
    
                .colz {

    
                    .colz-icon {
                        visibility: hidden;
    
                        a {

                        }
                    }
                    
                }
    
                .image {
                    
                    img {
                        visibility: hidden;
                    }
                }

                h6 {

                }
            }
    
            .contactForm-right {
                padding: 0;
                height: 80%;
                border-radius: 20px 20px 20px 20px;
                // background-color: red;
                background-color: transparent;

                .form-top {
                    width: 100%;
    
                    .input-field {

    
                        input {

                        }
    
                        textarea {

                        }
    
                        label {

                        }
                    }
    
                    .submit-btn {

                    }
                }
            }
        }
    }
}