.about-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 100vh;
    height: auto;
    // justify-content: center;
    overflow-x: hidden;
    padding-bottom: 50px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    .top {
        width: 100%;
        display: flex;
        // height: auto;
        // background: blue;

        .left {
            width: 50%;
            height: 395px;
            // background-color: bisque;
    
            img {
                // width: 750px;
                // height: 620px;
                width: 100%;
                height: 100%;
                opacity: 0.8;
            }
        }
    
        .right {
            width: 50%;
            // height: 100%;
            // background-color: aquamarine;
    
            .top {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20%;
                padding-top: 80px;
    
                h1 {
                    color: darkblue;
                    // margin: 0;
                    padding-bottom: 30px;
                    font-size: 25px;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                }
            }
    
            .body {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: justify;
    
                p {
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    width: 90%;
                    height: 90%;
                    font-weight: 100;
                    z-index: 1;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .bottom {
        // background: red;

        .card-section {
            align-items: center;
            justify-content: center;
            width:100%;
            // height: 30vh;
            display: grid;
            background-color: transparent;
            grid-template-columns: auto auto auto;
            grid-auto-flow: row;
            grid-gap: 5px;
            // background-color: #006FB4;

            .card-fram {
                width: 420px;
                height: 220px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: space-between;
                background-color: rgba(255, 255, 255, 0.6);
                transition: 0.3s ease all;
                border-radius: 10px;
                overflow: hidden;
                border: solid white 4px;
                margin: 10px;
                border:  solid blue 2px;
    
                .card-header {
                    width: 94%;
                    height: 30px;
                    // background-color: red;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    .nameOfTheCard {
    
                        p {
                            font-weight: bold;
                            color: #006FB4;
                        }
                    }
                }
    
                .card-body {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    p {
                        padding-top: 10px;
                        text-align: justify;
                        width: 95%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1239px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 380px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 360px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: 1136px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 350px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: 1320px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 400px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: 1290px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 380px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 360px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: 1150px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 340px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: 1115px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 340px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    .about-container { 
        .bottom {
            .card-section {
                .card-fram {
                    width: 300px;
                    height: 280px;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .about-container { 
        width: 100%;
        background-image: url('../../images/aboutImage.png');
        background-size: cover;
        background-position: center;
        .top {
    
            .left {
                width: 0;
                height: auto;

                img {
                    visibility: hidden;
                }
            }
        
            .right {
                width: 100%;
            }
        }
        
        .bottom {
            .card-section {
                grid-template-columns: auto;
                .card-fram {
                    width: 800px;
                    height: 150px;
                }
            }
        }
    }
}

@media (max-width: 820px) {
    .about-container { 
        .top {
    
            .left {

                img {

                }
            }
        
            .right {
            }
        }
        
        .bottom {
            .card-section {
                .card-fram {
                    width: 750px;
                    height: 150px;
                }
            }
        }
    }
}

@media (max-width: 760px) {
    .about-container { 
        .top {
    
            .left {

                img {

                }
            }
        
            .right {
            }
        }
        
        .bottom {
            .card-section {
                .card-fram {
                    width: 650px;
                    height: 150px;
                }
            }
        }
    }
}

@media (max-width: 660px) {
    .about-container { 
        .top {
    
            .left {

                img {

                }
            }
        
            .right {
            }
        }
        
        .bottom {
            .card-section {
                .card-fram {
                    width: 550px;
                    height: 160px;
                }
            }
        }
    }
}

@media (max-width: 560px) {
    .about-container { 
        .top {
    
            .left {

                img {

                }
            }
        
            .right {
            }
        }
        
        .bottom {
            .card-section {
                .card-fram {
                    width: 500px;
                    height: 180px;
                }
            }
        }
    }
}

@media (max-width: 510px) {
    .about-container { 
        .top {
    
            .left {

                img {

                }
            }
        
            .right {
            }
        }
        
        .bottom {
            .card-section {
                .card-fram {
                    width: 400px;
                    height: 210px;
                }
            }
        }
    }
}

@media (max-width: 410px) {
    .about-container { 
        .top {
    
            .left {

                img {

                }
            }
        
            .right {
            }
        }
        
        .bottom {
            .card-section {
                .card-fram {
                    width: 300px;
                    height: 280px;
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .about-container { 
        .top {
    
            .left {

                img {

                }
            }
        
            .right {
            }
        }
        
        .bottom {
            .card-section {
                .card-fram {
                    width: 250px;
                    height: 350px;
                }
            }
        }
    }
}